.add-rule-container h4 {
  /* font-size: 16px;
    color: #111827;
    font-weight: 600; */
  font-size: 16px;
  color: #111827;
  font-weight: 600;
  line-height: 24px;
  font-weight: 600 !important;
}
.add-rule-container p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  height: 24px;
  color: #6b7280;
}
.add-rule-container h5 {
  color: #6b7280;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.add-rule-container.add-new-rule h5 {
  font-size: 16px;
  color: #111827;
  font-weight: 600 !important;
  line-height: 24px;
}
.add-rule-container .rule-box {
  border: 1px #e5e7eb solid;
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 15px 20px;
  position: relative;
  cursor: pointer;
}
.add-rule-container .rule-box .box-header {
  color: #111827;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.add-rule-container .rule-box .box-header i {
  padding-right: 10px;
}
.add-rule-container .rule-box .box-body {
  padding-top: 15px;
  padding-left: 20px;
}
.add-rule-container .rule-box .box-body p {
  font-size: 14px;
  font-weight: 400;
  color: #6b7280;
  line-height: 21px;
  max-width: 301px;
}
.add-rule-container a {
  text-decoration: none;
}
.add-rule-container .rule-box .box-body select.form-select {
  width: 200px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  border-color: #d1d5db;
}
.add-rule-container .rule-box .radio-icon {
  background-image: url("../../../assets/images/icons/radio-inactive.svg");
  background-repeat: no-repeat;
  padding: 10px;
  position: absolute;
  right: 8px;
  top: 8px;
}
.add-rule-container .rule-box:hover {
  border-color: var(--baseColor);
  background-color: var(--hoverColor);
}
.add-rule-container .rule-box:hover .radio-icon {
  background-image: url("../../../assets/images/icons/radio-inactive.png");
}

.add-rule-container.add-new-rule .form-control {
  height: 40px !important;
}
.new-rule-name .form-control {
  height: 40px !important;
  width: 400px !important;
  border: 1px solid #d1d5db !important;
}
.add-rule-container.add-new-rule .form-control::placeholder {
  color: #9ca3af !important;
}

.ifpage-css {
  flex-grow: 1;
}
.form-field {
  width: 40%;
}
.ifpage-fom1 {
  width: 273px;
  height: 40px;
  left: 60px;
}

.focus-border-green:hover,
.focus-border-green:active,
.focus-border-green:focus {
  box-shadow: 0 0 0 1px var(--baseColor) !important;
  border-color: var(--baseColor) !important;
  border-radius: 4px;
}
.marketplace-select {
  border: 1px solid hsl(0, 0%, 80%);
  height: auto;
  border-radius: 5px;
  background-color: white;
  position: relative;
}
.marketplace-hover {
  border: 1px solid var(--baseColor) !important;
  height: auto;
  border-radius: 5px;
  background-color: white;
  position: relative;
}
.marketplace-dropdown {
  margin-top: 8px;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 10;
  max-height: 250px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  overflow: scroll;
  overflow-x: auto;
}
.marketplace-dropdown::-webkit-scrollbar {
  width: 4px !important;
}
.titleList-li,
.marketTitle {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  padding-top: 1px;
}
.marketTitle {
  display: flex;
  justify-content: space-around;
}
.border-bottom {
  margin-top: 10px;
}
.marketTag {
  display: inline-block;
  margin: 2px;
  border: 1px solid var(--baseColor);
  padding: 2px 4px;
  background-color: var(--hoverColor);
  color: var(--baseColor);
  border-radius: 5px;
}
/* .marketplaceTags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
} */
.removeIcon {
  cursor: pointer;
  padding: 0 5px;
  color: var(--baseColor);
}
.selectallborder {
  position: sticky;
  top: 0;
  background-color: white;
  margin: 1px 3px;
}

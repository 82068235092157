/* DateRangeFilter.module.css */
/* Scoped styles for DateRangeFilter component */
/* .custom-dropdown-header .date-text-con */
.DateRangeFilter {
}
.rs-picker-default .rs-picker-toggle {
  border: none !important;
  background-color: var(--baseColor) !important;
}
.rs-btn-close .rs-icon {
  display: none;
}
.DateRangeFilter .custom-dropdown {
  border: none;
  background-color: white;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  background-color: transparent !important;
}

.rs-picker-toggle-textbox {
  top: -3px !important;
}
.DateRangeFilter .clicked .custom-dropdown {
  background-color: #fffbde;
}

.DateRangeFilter .date-left-icon img {
  cursor: pointer;
}

.DateRangeFilter .date-text-con {
  color: #6b7280;
}

.DateRangeFilter .clicked .date-text-con {
  /*color: #00B470;*/
  color: var(--baseColor) !important;
}

.DateRangeFilter .dropdown-header .arrow.up {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.DateRangeFilter .dropdown-header .arrow.down {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.DateRangeFilter .rs-picker {
  margin-top: -5px;
}

.DateRangeFilter .rs-picker .rs-picker-toggle-caret {
  display: none;
}

.DateRangeFilter .dropdown-item {
  font-family: Plus Jakarta Sans;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5563 !important;
}

.rs-picker-toolbar-ranges {
  display: none !important;
}

.DateRangeFilter .input-group-text {
  border: 1px solid #dee2e6;
}

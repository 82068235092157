.btn {
  font-size: 14px !important;
}
.StepperContainer-0-2-1 {
  padding: 10px 24px 5px 24px;
}
.StepButton-0-2-4.active {
  border: 1px solid var(--baseColor) !important;
}
.steperDesign .active {
  background-color: var(--hoverColor) !important;
  color: var(--baseColor) !important;
}
.steperDesign .completed {
  background-color: var(--baseColor) !important;
}
.steperDesign .completed:hover {
  background-color: var(--baseColor) !important;
}

/* Custom Wizard Container */
.wizard-container {
  border: 1px solid #d1d5db;
  border-radius: 6px;
}
.wizard-container hr {
  border-top-color: #d1d5db;
}
.wizard-nav-btn-container {
  display: flex;
  margin-top: 40px;
}
.wizard-nav-btn-container > div {
  margin-left: 10px;
}
.wizard-nav-btn button {
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  padding: 5px 30px;
}
.wizard-nav-btn button.gray {
  background-color: #9ca3af;
  color: #ffffff;
  border-color: #9ca3af;
}
.wizard-nav-btn button.green {
  background-color: var(--baseColor);
  color: #000000;
  border-color: var(--baseColor);
}
.wizard-nav-btn button.outline {
  background-color: #ffffff;
  color: var(--baseColor);
  border: 1px solid var(--baseColor);
}
.wizard-container .wizard-stepper-container > div > div > div > button {
  background-color: #e5e7eb;
  border: 1px solid #e5e7eb;
}
.wizard-container .wizard-stepper-container > div > div > div > button.active {
  background-color: var(--hoverColor) !important;
  border: 1px solid var(--baseColor) !important;
}
.wizard-container .wizard-stepper-container > div > div > div > button span {
  font-size: 16px;
  font-weight: 600;
  color: #6b7280;
}
.wizard-container
  .wizard-stepper-container
  > div
  > div
  > div
  > button.completed {
  background-color: var(--baseColor);
}
.wizard-container
  .wizard-stepper-container
  > div
  > div
  > div
  > button.completed
  span {
  color: #ffffff;
}
.wizard-container .wizard-step-container .wizard-step {
  padding: 10px 20px;
  min-height: 300px;
}
.wizard-container .wizard-step-container .wizard-step .card {
  background-color: #f9fafb;
  border-radius: 6px;
  padding: 15px;
  border: 0px;
  margin: 15px 0;
}
.wizard-container .wizard-step-container .wizard-step .card .form-select,
.wizard-container .wizard-step-container .wizard-step .card .form-control {
  height: 40px;
}
.wizard-container .wizard-step-container .card .title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600 !important;
  color: #111827;
}
.wizard-container .wizard-step-container .card .form-row {
  display: flex;
}
.wizard-container
  .wizard-step-container
  .card
  .form-row
  > div.form-field-group {
  display: flex;
  flex: auto;
}
.wizard-container
  .wizard-step-container
  .card
  .form-row
  > div.form-field-group
  .form-field {
  margin-right: 10px;
  flex: auto;
}
.wizard-container .wizard-step-container .card .form-action {
  display: flex;
}
.wizard-container .wizard-step-container .card .form-action.a-2 {
  width: 100px;
}
.wizard-container .wizard-step-container .card .form-action.a-3 {
  width: 150px;
}
.wizard-container .wizard-step-container .card .form-action.a-4 {
  width: 200px;
}
.wizard-container .wizard-step-container .card .form-action > div {
  margin-left: 10px;
  text-align: center;
}
.wizard-container .wizard-step-container .card .icon-box-white {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  padding: 5px;
}
.wizard-container .wizard-step-container .card .icon-box-gray {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #f3f4f6;
  padding: 5px;
}

.wizard-container .wizard-step-container .add-more-btn {
  color: var(--baseColor) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 2px 4px;
}
.wizard-container .wizard-step-container .add-more-btn i {
  background-image: url("../../assets/images//icons/circle-add-icon.svg");
  filter: brightness(0%) invert(0);
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px;
  margin-right: 5px;
}
.wizard-container .wizard-step-container .add-more-btn:hover {
  background-color: var(--hoverColor) !important;
  border: 1px solid var(--baseColor) !important;
}
.wizard-container
  .wizard-step-container
  .card
  .gray-box
  .option-list-container
  .form-check
  .form-check-input {
  background-color: transparent;
  border-color: #9ca3af;
}
.wizard-container
  .wizard-step-container
  .card
  .gray-box
  .option-list-container
  .form-check
  .form-check-input:checked[type="checkbox"] {
  background-color: var(--baseColor);
  border-color: var(--baseColor);
}
.wizard-container
  .wizard-step-container
  .card
  .gray-box
  .option-list-container
  .form-check
  label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400 !important;
  color: #374151;
}

.list-container {
  display: flex;
  font-size: 18px;
  background-color: #eee;
  flex-direction: column;
}
.item-container {
  background-color: #fff;
  border: 1px solid black;
  padding: 25px 70px;
  margin: 15px 50px;
}
.rule-switch-container {
  display: flex;
}
.multiply-sympol-box {
  padding: 5px;
}
.css-t3ipsp-control:hover,
.css-t3ipsp-control:active,
.css-t3ipsp-control:focus {
  box-shadow: 0 0 0 1px var(--baseColor) !important;
  border-color: var(--baseColor) !important;
}

.Label-d3-0-2-15 {
  font-weight: 600 !important;
}

.Label-d7-0-2-29 {
  font-weight: 600 !important;
}
.Label-d11-0-2-41 {
  font-weight: 600 !important;
}
.Label-d15-0-2-53 {
  font-weight: 600 !important;
}
.Label-d19-0-2-65 {
  font-weight: 600 !important;
}
.Label-d23-0-2-77 {
  font-weight: 600 !important;
}

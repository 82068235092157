/* HeatMapChart.module.css */
/* Scoped styles for HeatMapChart component */
.HeatMapChart
{
}

    .HeatMapChart .custom-tooltip
    {
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .HeatMapChart .context-menu
    {
        display: none;
        position: absolute;
        margin-top: -50px;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        padding: 10px;
    }

        /* Style for context menu items */
        .HeatMapChart .context-menu a
        {
            display: block;
            padding: 5px 0;
            text-decoration: none;
            color: #333;
        }

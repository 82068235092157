/* DynamicWidthDropdown.module.css */
/* Scoped styles for DynamicWidthDropdown component */

.DynamicWidthDropdown
{
    display: inline-flex;
}

    .DynamicWidthDropdown .form-select.widget-select
    {
        font-weight: 600;
        font-size: 14px;
        color: #212529 !important;
    }

.overflow-ellipse {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ads-creation-list-container {
  padding-top: 10px;
}

.popup-custom-box ul li {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
}

.ads-creation-list-container-left .ads-content-row {
  display: inline-flex;
}

.ads-creation-list-container-left .ads-content-row .product-details {
  display: inline-flex;
  padding-left: 10px;
}

.ads-creation-list-container-left
  .ads-content-row
  .product-details
  .product-details-img {
  padding: 0 5px;
}

.ads-creation-list-container-left
  .ads-content-row
  .product-details
  .product-details-img
  img {
  max-width: 100%;
}

.ads-creation-list-container-left
  .ads-content-row
  .product-details
  .product-details-cont {
  padding: 0 10px;
}

.ads-creation-list-container-left .ads-content-row .product-title {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  font-family: Plus Jakarta Sans;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ads-creation-list-container-left .ads-content-row .product-subtitle {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.ads-creation-list-container-left
  .ads-content-row
  .product-subtitle
  .bullet-point {
  margin-left: 20px;
}

.ads-creation-list-container-right {
  display: flex;
}

.ads-creation-list-container-right .three-dot {
  width: 30px;
  cursor: pointer;
}

.ads-container-row .tab ul li span.rounted-icon {
  margin-right: 10px;
}

.ads-container-row .tab ul {
  list-style: none;
  display: flex;
  margin: 0px;
  padding: 0px;
}

.ads-container-row .tab ul li {
  padding: 0 15px 10px 15px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.ads-container-row .tab ul li.active {
  border-bottom: 1px var(--baseColor) solid;
  color: var(--baseColor);
}

.ads-container-row .tab ul li i {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.ads-container-row .tab ul li.advertise-anywhere {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 500;
  color: var(--baseColor);
}

.ads-container-row .tab .ads-rules-list-container {
  padding: 25px 30px;
  background-color: #f3f4f6;
  max-height: 170px;
  overflow: auto;
}

.ads-container-row .tab .ads-rules-list-container > div {
  display: none;
}

.ads-container-row .tab .ads-rules-list-container > div.active {
  display: block;
}

.accordion-item.rules-list-item {
  background: transparent;
}
.accordion-item {
  position: relative;
}
.ads-container-row
  .tab
  .ads-rules-list-container
  .rules-list-item
  .rules-list-item-summary {
  background-color: #ffffff;
  padding: 23px 10px;
  border-radius: 8px;
  display: flex;
  position: relative;
}

.ads-container-row
  .tab
  .ads-rules-list-container
  .rules-list-item
  .rules-list-item-summary
  div {
  padding: 5px 10px;
}

.ads-container-row
  .tab
  .ads-rules-list-container
  .rules-list-item
  .rules-list-item-summary
  .rule-title-name {
  font-family: Plus Jakarta Sans !important;
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.ads-container-row
  .tab
  .ads-rules-list-container
  .rules-list-item
  .rules-list-item-summary
  .down-arrow {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.ads-container-row
  .tab
  .ads-rules-list-container
  .rules-list-item
  .rules-list-item-details {
  background-color: #ffffff;
  padding: 15px;
  position: absolute;
  margin-top: 3px;
  z-index: 3;
}

.ads-container-row
  .tab
  .ads-rules-list-container
  .rules-list-item
  .rules-list-item-details
  .edit-link {
  position: absolute;
  right: 15px;
  top: 15px;
}

.ads-container-row
  .tab
  .ads-rules-list-container
  .rules-list-item
  .rules-list-item-details
  h6 {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  color: #111827;
}

.ads-container-row
  .tab
  .ads-rules-list-container
  .rules-list-item
  .rules-list-item-details
  p {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  color: #111827;
}

.createAdsModel .modal-content {
  width: 600px;
}

.createAdsModel .addTagModelContainers h4 {
  font-family: Plus Jakarta Sans !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.createAdsModel .addTagModelContainers p {
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.createAdsModel .create-ads-modal-radio {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #e5e7eb !important;
  border-radius: 4px !important;
  height: 50px !important;
  color: #171717 !important;
  padding-top: 10px;
  height: 30px;
}

.createAdsModel .create-ads-modal-radio1 {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #e5e7eb !important;
  border-radius: 4px !important;
  height: 150px !important;
  color: #171717 !important;
  padding-top: 10px;
}

.createAdsModel .create-ads-modal-radio1 .radio-icon {
  background-image: url("../../../assets/images/icons/radio-inactive.svg");
  background-repeat: no-repeat;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 15px;
}

.createAdsModel .create-ads-modal-radio .radio-icon {
  background-image: url("../../../assets/images/icons/radio-inactive.svg");
  background-repeat: no-repeat;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 15px;
}

.createAdsModel .create-ads-modal-radio1:hover,
.createAdsModel .create-ads-modal-radio1.selected {
  border: 1px solid var(--baseColor) !important;
  background-color: var(--hoverColor) !important;
}

.createAdsModel .create-ads-modal-radio:hover,
.createAdsModel .create-ads-modal-radio.selected {
  border: 1px solid var(--baseColor) !important;
  background-color: var(--hoverColor) !important;
}

.createAdsModel .create-ads-modal-radio:hover .radio-icon,
.createAdsModel .create-ads-modal-radio.selected .radio-icon {
  background-image: url("../../../assets/images/icons/radio-inactive.png");
}

.createAdsModel .create-ads-modal-radio1:hover .radio-icon,
.createAdsModel .create-ads-modal-radio1.selected .radio-icon {
  background-image: url("../../../assets/images/icons/radio-inactive.png");
}

.createCampaindBtnContainer a {
  text-decoration: none;
  font-size: 16px !important;
  line-height: 2.2;
}

.create-campaign-container p a {
  position: relative;
  cursor: pointer;
}

.create-campaign-container > h5 {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  color: #111827;
}

.create-campaign-container > p {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.create-campaign-container > p a {
  font-weight: 400;
  text-decoration: underline;
  color: var(--baseColor);
}

.create-campaing-form .form-group {
  padding-bottom: 15px;
}

.create-campaing-form label {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.create-campaing-form input[type="text"] {
  height: 40px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
}
.create-campaing-form {
  height: 200px !important;
}
.create-campaing-form .added-groups-list {
  display: flex;
  flex-wrap: wrap;
}

.create-campaing-form .added-groups-list div {
  margin: 10px 10px 10px 0px;
  height: 40px;
  background-color: #f3f4f6;
  border-radius: 4px;
  padding: 7px 5px 5px 10px;
  font-size: 16px;
}

.create-campaing-form .btn-outline {
  background-color: transparent;
  border: 1px solid var(--baseColor);
  color: var(--baseColor) !important;
  font-size: 16px;
  font-weight: 600;
}

label.form-check-label {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
}

.form-button-group {
  padding: 0 30px;
  text-align: right;
}

.form-button-group button {
  margin-left: 20px;
  min-width: 100px;
  height: 40px !important;
}

.form-button-group button.btn-outline-primary {
  border-color: var(--baseColor);
}

.createAdsModel.open {
  display: block !important;
}

.add-keyword-container {
  position: relative;
}

.add-keyword-container textarea {
  height: 160px !important;
}
.create-ads {
  width: 100% !important;
  height: 50px !important;
}
.add-keyword-container .add-keyword-btn {
  position: absolute;
  right: 5px;
  bottom: 5px;
  height: 30px;
  border-radius: 3px;
  border-color: var(--hoverColor);
  background-color: var(--hoverColor) !important;
  color: var(--baseColor) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px;
}

.create-campaing-form .table-responsive {
  border: 1px #d1d5db solid;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
}

.create-campaing-form table.table {
  border: 0px;
}

.create-campaing-form table.table thead tr th {
  border-bottom: 0px;
  color: #9ca3af;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
}

.create-campaing-form table.table tbody tr td {
  border-bottom: 0px;
  color: #111827;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
}

table.table tbody tr td.more-action i {
  cursor: pointer;
}

.select-deselect-container {
  position: relative;
}

.select-deselect-container .select-deselect-all {
  position: absolute;
  right: 0px;
  top: 0px;
  text-align: right;
}

.select-deselect-container .select-deselect-all span {
  cursor: pointer;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  color: var(--baseColor);
  text-decoration: underline;
}

.remove-all-link span {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  color: var(--baseColor);
  cursor: pointer;
}

.remove-all-link {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  color: var(--baseColor);
  cursor: pointer;
}
/* .popup-custom-box {
  position: absolute;
  border-radius: 5px;
  width: 225px;
  background-color: rgba(17, 24, 39, 0.8);
  color: #ffffff;
  padding: 10px;
  left: -100px;
  top: 30px;
  display: none;
} */

.popup-custom-box p {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
}

.date-picker-autocampign {
  border-radius: 0.25rem;
  height: 35px !important;
  border: 1px solid #ced4da !important;
}

.popup-custom-box ul li {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
}

.create-campaign-container p a:hover .popup-custom-box {
  display: block;
}

/* ///-----------/// */

.marketplaces-id {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.marketplace-item {
  border: 1px solid #f1f1f1;
  padding: 10px;
  border-radius: 0.5rem;
}

.marketplace-selected {
  border: 1px solid var(--baseColor);
  background-color: var(--hoverColor);
  padding: 10px;
  border-radius: 0.5rem;
}

.flag-icons {
  width: 20px;
}

.add-plus {
  border: 0.5px solid var(--baseColor);
  border-radius: 50%;
  padding: 0.1rem;
}

/*  createads */
.createAdsModel .create-ads-modal-radio {
  min-height: 60px;
  padding: 10px 10px;
}

.create-ads-modal-radio label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.create-ads-modal-radio1 label {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust the right margin as needed */
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.create-ads-modal-radio1 .checkbox-container label {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}

.create-ads-modal-radio1 input {
  margin-right: 15px; /* Adjust the right margin for the checkbox */
  transform: scale(1.8); /* Adjust the scale factor as needed */
  color: #d1d5db !important;
}

.create-ads-modal-radio input {
  margin-right: 15px; /* Adjust the right margin for the checkbox */
  transform: scale(1.8); /* Adjust the scale factor as needed */
  color: #d1d5db !important;
}

.checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-container label {
  margin-left: 30px; /* Adjust the margin as needed */
}
/* Style for the checkbox */
.checkbox-label {
  position: relative;
  padding-left: 30px; /* Adjust as needed */

  display: inline-block;
}

/* Style for the tick mark */
.checkbox-label::before {
  content: "";
  display: inline-block;
  width: 14px; /* Adjust the size as needed */
  height: 14px; /* Adjust the size as needed */
  border: 2px solid blue; /* Default border color */
  background-color: white; /* Default background color */
  position: absolute;
  left: 0;
  top: 0;
}

/* Style for the checked state */
.checkbox-label input:checked + .checkbox-label::before {
  border-color: green; /* Change the border color to green when checked */
  background-color: green; /* Change the background color to green when checked */
}

.input-span {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0px 0.25rem 0.25rem 0px !important;
}
/* Style for the checkbox */

/* }
  .connection {
    text-align: center;
    align-items: center;
    max-width: 200px;
    display: flex;
    flex-direction: column; 
    gap: 10px; 
  }

  .box {
    font-size: small;
    text-align: center;
    align-items: center;
    height: 20px;
    border-radius: 3px;
    background-color: var(--baseColor);
    color: #f3f4f6;
    white-space: nowrap; 
    position: relative;
    padding: 0 10px;
  }

  .box::after {
    content: '';
    position: absolute;
    top: 100%; 
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 10px; 
    background-color: #000;
  }
  
  

  .nested-connection {
    content: '';
    display: flex;
    gap: 10px; 
    margin-top: 10px; 
  }
  .nested-connection::before {
    content: '';
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: calc(100% - 20px); 
    background-color: #000;
    z-index: -1; 
  }
   */
/* styles.css */

.hoverable:hover {
  background-color: lightgreen;
  border-color: var(--baseColor); /* Change the border color on hover */
}

.hoverable button {
  width: 100%;
  height: 100%;
  background: none;
  cursor: pointer;
  font-size: 12px;
  border: none;
}

.placeholder-input-wrapper {
  position: relative;
}

.placeholder-input-wrapper-manual {
  position: relative;
}

.set-dynamic {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #111827 !important;
  font-family: Plus Jakarta Sans !important;
}

.campaign-name-text {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #111827 !important;
}

.placeholder-input-wrapper::before {
  content: "{Layer} | {Auto} | {ASIN} ";
  position: absolute;
  top: 0;
  color: #374151 !important;
  left: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  font-family: Plus Jakarta Sans !important;
  border-radius: 0.25rem 0 0 0.25rem;
  height: 37px;
  background-color: #e5e7eb;
  padding: 0px 10px; /* Adjust this value to move the content to the right */
  pointer-events: none; /* Make sure the pseudo-element doesn't interfere with input events */
  line-height: 40px; /* Adjust this value to move the content downward */
  z-index: 1; /* Set a z-index value that works for your layout */
}

.placeholder-input-wrapper-manual:before {
  content: "{Layer} | {Manual} | {ASIN} ";
  position: absolute;
  top: 0;
  color: #374151 !important;
  left: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  font-family: Plus Jakarta Sans !important;
  border-radius: 0.25rem 0 0 0.25rem;
  height: 37px;
  background-color: #e5e7eb;
  padding: 0px 10px; /* Adjust this value to move the content to the right */
  pointer-events: none; /* Make sure the pseudo-element doesn't interfere with input events */
  line-height: 40px; /* Adjust this value to move the content downward */
  z-index: 1; /* Set a z-index value that works for your layout */
}

.custom-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.setting-button {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* flex-wrap: wrap; */
}

.setting-button > div {
  flex-grow: 1;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 6.9px 10px;
  border: 1px solid #ced4da !important;
  background-color: white !important;
  border-radius: 0.25rem;
  color: #111827;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.setting-button .selected {
  background-color: var(--hoverColor) !important;
  border: 1px solid var(--baseColor) !important;
  color: var(--baseColor) !important;
}

.daily-budget {
  margin-top: 13px;
  display: flex;
}

.input-span-daily {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.25rem 0 0px 0.25rem !important;
}

.custom-input-daily {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-input-main {
  display: block;
  width: 100%;
  padding: 0.375rem 0.375rem 0.375rem 190px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-left: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-input-main-manual {
  display: block;
  width: 100%;
  padding: 0.375rem 0.375rem 0.375rem 208px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-left: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.budget-start {
  display: flex;
  justify-content: space-between;
}

.Daily-Budget-dates {
  display: flex;
  gap: 10px;
}

.Daily-Budget-dates > div {
  flex-grow: 1;
}

.manual-product {
  width: 350px !important;
  position: relative !important;
  display: inline-flex !important;
  border: 1px solid #ced4da !important;
  border-radius: 5px !important;
  height: 35px !important;
}

.icon-search {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 5px;
}

.merge-box {
  position: relative;
  display: inline-flex;
}

.category-text {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 380px;
  white-space: nowrap;
}

.manual-product-search {
  width: 375px;
  height: 280px;
  background-color: white !important;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
}

.input-box-control-auto.resize {
  height: 280px !important;
}

.manual-border {
  align-items: center;
  display: flex;
  padding: 10px 10px;
}

.add-keyword-btn.resize {
  right: 9px;
  bottom: 20px;
}

.search-result {
  padding: 5px 10px;
  height: 200px;
  overflow: scroll;
  overflow-x: hidden;
}

.search-result::-webkit-scrollbar {
  width: 6px;
}

.loading-screen {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* .add-gropu-btn {
  background-image: url("../../../assets/images/Vertical\ container.svg");
  background-repeat: no-repeat;
  background-size: auto;
  border: 1px solid var(--baseColor);
  padding: 5px 25px;
  display: inline-block;
  border-radius: 0.25rem;
  color: var(--baseColor);
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
} */
.add-gropu-btn {
  background-image: url("../../../assets/images/Vertical%20container.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right 5px center;
  border: 1px solid var(--baseColor);
  padding: 5px 35px 5px 15px;
  display: inline-block;
  border-radius: 0.25rem;
  color: var(--baseColor);
  font-family: "Plus Jakarta Sans", sans-serif; /* Ensure correct font-family syntax */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
/* YourComponent.css */
/* YourComponent.css */
.root {
  width: 420px;
  height: 165px;
  position: relative;
  background: var(--hoverColor);
  border-radius: 4px;
  overflow: hidden;
  border: 1px var(--baseColor) solid;
}

.button {
  padding: 3px 8px 5px 8px;
  position: absolute;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  color: white;
  font-size: 12px;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
  word-wrap: break-word;
}

.line {
  position: absolute;
  border: 2px var(--baseColor) solid;
  transform-origin: 0 0;
  transform: rotate(-90deg);
}

.circle {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 388px;
  top: 8px;
}

.circle-inner {
  width: 19.2px;
  height: 19.2px;
  left: 2.4px;
  top: 2.4px;
  position: absolute;
}

.circle-inner-background {
  width: 14.4px;
  height: 14.4px;
  left: 2.4px;
  top: 2.4px;
  position: absolute;
  background: var(--baseColor);
  border-radius: 28px;
}

.circle-inner-border {
  width: 19.2px;
  height: 19.2px;
  left: 0px;
  top: 0px;
  position: absolute;
  border-radius: 10px;
  border: 1px var(--baseColor) solid;
}

.placement-icon {
  background-image: url("../../../../public/assets/images/icons/task-square.svg");
  background-repeat: no-repeat;
  background-size: auto;
  display: inline-block;
  background-position: left 5px center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 5px 30px;
  color: #111827;
}

.Setting-icon {
  background-image: url("../../../../public/assets/images/icons/setting-2.svg");
  background-repeat: no-repeat;
  background-size: auto;
  display: inline-block;
  background-position: left 5px center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 5px 30px;
  color: #111827;
}

.marketplace-icon {
  background-image: url("../../../../public/assets/images/icons/globe-asia-australia.svg");
  background-repeat: no-repeat;
  background-size: auto;
  display: inline-block;
  background-position: left 5px center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 5px 30px;
  color: #111827;
}

.Targeting-icon {
  background-image: url("../../../../public/assets/images/icons/gps.svg");
  background-repeat: no-repeat;
  background-size: auto;
  display: inline-block;
  background-position: left 5px center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 5px 30px;
  color: #111827;
}

.custom-input-text {
  color: var(--AiGray-900, #111827) !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.title-card-tab {
  color: var(--AiGray-500, #6b7280) !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.target-types {
  color: var(--AiGray-900, #111827) !important;
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.keyword-added {
  color: #6b7280;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.placement-text {
  color: #111827;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600 !important;
}

.place-holder {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.input-box-control-auto::placeholder {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.NegativeProducts .nav-link.active {
  height: 60px;
  color: black !important;
  border: 0px;
  border-bottom: 1px var(--baseColor) solid !important;
}

.here-tooltip {
  color: var(--baseColor);
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-decoration-line: underline;
}
.radio-auto {
  width: 25px;
  height: 25px;
  font-size: 16px;
}
.img-layout:hover {
  border: 2px solid var(--baseColor);
  background-color: var(--hoverColor);
}
.img-layout:hover .input-round {
  background-image: url("../../../assets/images/icons/radio-inactive.png");
}
.input-round {
  background-image: url("../../../assets/images/icons/radio-inactive.svg");
  background-repeat: no-repeat;
  padding: 10px;
}
.selected {
  border: 2px solid var(--baseColor) !important;
  background-color: var(--hoverColor);
}
.selectedInput {
  background-image: url("../../../assets/images/icons/radio-inactive.png");
}
.img-layout {
  min-width: 440px;
  text-align: center;
  /* max-width: 600px; */
  /* min-width: 100px; */
  border: 2px solid #ced4da;
  border-radius: 5px;
  /* width: 500px; */
  padding: 10px 0px;
}
.img-layout1 {
  text-align: center;
  min-width: 650px;
  border: 2px solid #ced4da;
  border-radius: 5px;
  /* width: 500px; */
  padding: 10px 0px;
}
.img-layout1 .auto-border {
  background-color: var(--baseColor);
  color: white;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 0.3rem;
  width: 150px;
  text-align: center;
  padding: 6px 8px;
  margin: 10px 0px 30px 0px;
  z-index: 5;
}
.img-layout .auto-border {
  background-color: var(--baseColor);
  color: white;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 0.3rem;
  width: 150px;
  text-align: center;
  padding: 6px 8px;
  margin: 10px 0px 30px 0px;
  z-index: 5;
}

.auto-img-border {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auto-border-line2 {
  border-left: 3px solid var(--baseColor);
  position: absolute;
  left: 50%;
  top: 29px;
  height: 140px;
  width: 10px;
}

.auto-border-line {
  border-left: 3px solid var(--baseColor);
  position: absolute;
  left: 50%;
  top: 29px;
  height: 113px;
  width: 10px;
}
.auto-border-line1 {
  border-left: 3px solid var(--baseColor);
  position: absolute;
  left: 50%;
  top: 30px;
  height: 40px;
  width: 10px;
}

.auto-border-doubleline {
  border-left: 3px solid var(--baseColor);
  top: 0px;
  left: 50%;
  position: absolute;
  height: 40px;
  /* width: 10px; */
}
.autoadgrp-border-doubleline {
  border-left: 3px solid var(--baseColor);
  top: 0px;
  left: 50%;
  position: absolute;
  height: 95px;
  /* width: 10px; */
}
.autoadgrp-border-doubleline1 {
  border-left: 3px solid var(--baseColor);
  top: 20px;
  left: 50%;
  position: absolute;
  height: 155px;
}
.auto-center-item {
  display: flex;
  flex-direction: column;
}
.auto-border-item {
  text-align: center;
  background-color: var(--baseColor);
  border-radius: 0.3rem;
  color: white;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 6px 8px;
  margin: 20px 5px;
  position: relative;
  z-index: 1;
  text-align: center;
  /* width: 130px; */
}
.man-border-line1 {
  border-left: 3px solid var(--baseColor);
  position: absolute;
  left: 50%;
  top: 30px;
  height: 140px;
}
.auto-border-item1 {
  text-align: center;
  background-color: var(--baseColor);
  border-radius: 0.3rem;
  color: white;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 6px 8px;
  margin: 20px 5px;
  position: relative;
  z-index: 1;
  text-align: center;
  /* width: 130px; */
}
.auto-border-item2 {
  text-align: center;
  background-color: var(--baseColor);
  border-radius: 0.3rem;
  color: white;
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 6px 8px;
  margin: 20px 5px;
  position: relative;
  z-index: 1;
  text-align: center;
  width: 150px;
}
.horizontal-line {
  top: -1px;
  border-bottom: 3px solid var(--baseColor);
  height: 1px;
  position: absolute;
  /* display: flex;
  flex-grow: 1; */
  right: 71px;
  left: 43px;
}
.horizontal-line1 {
  top: -1px;
  border-bottom: 3px solid var(--baseColor);
  height: 1px;
  position: absolute;
  right: 64px;
  left: 49px;
}
.horizontal-line1-man {
  top: -1px;
  border-bottom: 3px solid var(--baseColor);
  height: 1px;
  position: absolute;
  right: 71px;
  left: 72.5px;
}
.horizontal-line-man {
  top: -1px;
  border-bottom: 3px solid var(--baseColor);
  height: 1px;
  position: absolute;
  right: 68.5px;
  left: 72px;
}
.marketplace-selected {
  border: 1px solid var(--baseColor);
  background-color: var(--hoverColor);
  padding: 10px;
  border-radius: 0.5rem;
}
